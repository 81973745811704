import { Button, Dialog, DialogTitle, makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import clsx from "clsx";
import { VFC } from "react";
import { LogWorkForm, LogWorkFormProps } from "../forms/LogWorkForm";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    paper: {
      maxWidth: 460,
      padding: theme.spacing(2, 2, 5),
      backgroundColor: theme.colors.white,
    },
    closeBtn: {
      position: "absolute",
      zIndex: 1,
      top: theme.spacing(0.25),
      right: theme.spacing(0.25),
      margin: "0 0 0 auto",
      minWidth: 0,
    },
  }),
  {
    classNamePrefix: "LogWorkDialog",
  }
);

export type LogWorkDialogJSSClassKey = keyof ReturnType<typeof useStyles>;

export type LogWorkDialogProps = LogWorkFormProps & {
  classes?: Partial<ClassNameMap<LogWorkDialogJSSClassKey>>;
  className?: string;
  open: boolean;
  taskTitle?: string;
};

export const LogWorkDialog: VFC<LogWorkDialogProps> = ({ className, classes: extClasses, open, taskTitle, onCancel, ...rest }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <Dialog className={clsx(classes.root, className)} classes={{ paper: classes.paper }} open={open} onClose={onCancel} fullWidth>
      <Button onClick={onCancel} className={classes.closeBtn} color="primary" variant="text">
        <CloseRoundedIcon fontSize="small" />
      </Button>
      <DialogTitle>Log Work: {taskTitle || "Task"}</DialogTitle>
      <LogWorkForm onCancel={onCancel} {...rest} />
    </Dialog>
  );
};
