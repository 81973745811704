import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { forwardRef, PropsWithChildren, ReactElement, ReactNode, useCallback, useState } from "react";
import { useIsMountedRef } from "../hooks/useIsMountedRef";
import { SnoozeOption } from "../reclaim-api/Events";
import { AsyncButton } from "./forms/AsyncButton";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 260,
      minWidth: 150,
      display: "flex",
      flexDirection: "column",
    },
    header: {
      fontSize: 15,
      fontWeight: 600,
      marginBottom: theme.spacing(2),
    },
    children: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(-1.5),
    },
    snoozeOptions: {
      display: "flex",
      gap: theme.spacing(),
      flexWrap: "wrap",
    },
    snoozeOptionsThinking: {
      pointerEvents: "none",
      opacity: 0.5,
    },
    snoozeOption: {
      whiteSpace: "nowrap",
    },
    rescheduleButtonSelected: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    rescheduleButton: {
      alignSelf: "end",
      marginTop: theme.spacing(2),
    },
  }),
  {
    classNamePrefix: "Reschedulator",
  }
);

const SNOOZE_LABELS: Record<SnoozeOption, string> = {
  FROM_NOW_15M: "15min",
  FROM_NOW_30M: "30min",
  FROM_NOW_1H: "1hr",
  FROM_NOW_2H: "2hrs",
  FROM_NOW_4H: "4hrs",
  TOMORROW: "1 day",
  IN_TWO_DAYS: "2 days",
  NEXT_WEEK: "1 week",
};

export type ReschedulatorRescheduleHandler<S extends string = SnoozeOption> = (snooze: S) => Promise<void> | void;

type ReschedulatorSnoozeButtonProps<S extends string> = {
  className?: string;
  snoozeOption: S;
  label: string;
  selectable?: boolean;
  onClick: ReschedulatorRescheduleHandler<S>;
};

function ReschedulatorSnoozeButton<S extends string>({
  className,
  snoozeOption,
  label,
  selectable,
  onClick,
}: PropsWithChildren<ReschedulatorSnoozeButtonProps<S>>) {
  const handleReschedule = useCallback(() => {
    return onClick(snoozeOption);
  }, [onClick, snoozeOption]);

  return selectable ? (
    <Button className={clsx(className)} onClick={handleReschedule} variant="outlined" size="small">
      {label}
    </Button>
  ) : (
    <AsyncButton className={clsx(className)} onClick={handleReschedule} variant="outlined" size="small">
      {label}
    </AsyncButton>
  );
}

export type ReschedulatorJSSClassKey = keyof ReturnType<typeof useStyles>;

export type ReschedulatorPropsSnoozeOptionExtension = {
  labelOverrides?: Partial<Record<SnoozeOption, string>>;
};

export type ReschedulatorPropsGenericExtension<S extends string> = {
  labels: Record<S, string>;
};

export type ReschedulatorProps<S extends string = SnoozeOption> = {
  classes?: Partial<ClassNameMap<ReschedulatorJSSClassKey>>;
  className?: string;
  snoozeOptions: readonly S[];
  onReschedule: ReschedulatorRescheduleHandler<S>;
  hasRescheduleButton?: boolean;
  header?: ReactNode;
  children?: ReactNode;
} & (S extends SnoozeOption ? ReschedulatorPropsSnoozeOptionExtension : ReschedulatorPropsGenericExtension<S>);

export const Reschedulator = forwardRef<HTMLDivElement, ReschedulatorProps<string>>(
  (
    {
      className,
      classes: extClasses,
      snoozeOptions,
      onReschedule,
      hasRescheduleButton,
      header,
      children,
      ...labelProps
    },
    ref
  ) => {
    const classes = useStyles({
      classes: extClasses,
    });

    const [selectedSnooze, setSelectedSnooze] = useState<string>();

    const labelMap: Record<string, string> = {
      ...SNOOZE_LABELS,
      ...(labelProps as ReschedulatorPropsSnoozeOptionExtension).labelOverrides,
      ...(labelProps as ReschedulatorPropsGenericExtension<string>).labels,
    };

    const [thinking, setThinking] = useState(false);
    const isMountedRef = useIsMountedRef();

    const handleReschedule = useCallback(
      async (snooze: string): Promise<void> => {
        setThinking(true);
        await onReschedule(snooze);
        if (isMountedRef.current) setThinking(false);
      },
      [isMountedRef, onReschedule]
    );

    const handleRescheduleButtonClick = useCallback(async () => {
      if (selectedSnooze) await handleReschedule(selectedSnooze);
    }, [handleReschedule, selectedSnooze]);

    const handleSnoozeButtonClick = useCallback(
      async (snooze: string): Promise<void> => {
        if (hasRescheduleButton) setSelectedSnooze(snooze);
        else await handleReschedule(snooze);
      },
      [handleReschedule, hasRescheduleButton]
    );

    return (
      <div className={clsx(classes.root, className)} ref={ref}>
        <Typography variant="h4" className={classes.header}>
          {header || "Reschedule"}
        </Typography>
        {children && <Box className={classes.children}>{children}</Box>}
        <Box className={clsx(classes.snoozeOptions, { [classes.snoozeOptionsThinking]: thinking })}>
          {snoozeOptions.map((snoozeOption) => (
            <ReschedulatorSnoozeButton<string>
              key={snoozeOption}
              selectable={hasRescheduleButton}
              className={clsx(classes.snoozeOption, {
                [classes.rescheduleButtonSelected]: selectedSnooze === snoozeOption,
              })}
              onClick={handleSnoozeButtonClick}
              snoozeOption={snoozeOption}
              label={labelMap[snoozeOption] || snoozeOption}
            />
          ))}
        </Box>
        {hasRescheduleButton && (
          <AsyncButton
            className={classes.rescheduleButton}
            onClick={handleRescheduleButtonClick}
            disabled={!selectedSnooze}
            variant="contained"
            color="primary"
          >
            Reschedule
          </AsyncButton>
        )}
      </div>
    );
  }
  // TypeScript + forwardRef + generics don't play well together
) as unknown as <S extends string = SnoozeOption>(props: ReschedulatorProps<S>) => ReactElement<ReschedulatorProps<S>>;
