import { useShortcut } from "./useShortcut";

export const useMicroShortcuts = (submitCallback: () => void, cancelCallback: () => void) => {
  useShortcut(
    "escape",
    (e) => {
      // Prevent other key listeners from hijacking
      e.stopPropagation();
      cancelCallback();
    },
    [cancelCallback]
  );

  useShortcut(
    "enter",
    (e) => {
      // Prevent other key listeners from hijacking
      e.stopPropagation();
      submitCallback();
    },
    [submitCallback]
  );
};
